/* For use in src/lib/core/theming/_palette.scss */

$md-primary: (
  50 : #e4f4fd,
  100 : #bce3fb,
  200 : #8fd0f8,
  300 : #62bdf5,
  400 : #40aef2,
  500 : #1ea0ef,
  600 : #1a98ee,
  700 : #168eec,
  800 : #1284e9,
  900 : #0a73e5,
  A100 : #dadada,
  A200 : #9b9b9b,
  A400 : #3c3c3c,
  A700 : #faa01e,
    contrast: (
      50 : #3c3c3c,
      100 : #3c3c3c,
      200 : #3c3c3c,
      300 : #3c3c3c,
      400 : #3c3c3c,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
      A100 : #3c3c3c,
      A200 : #3c3c3c,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);
