@import "theme";
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800|Open+Sans:400,600,800");
/* RETINA SETTINGS LOADED AT THE END */

/* MAIN COLORS */

$main-color: #1ea0ef;
$main-color-second: #faa01e;
$text-color: #3c3c3c;
$text-color-light: #9b9b9b;
$border-color: #dadada;
$background-color: #ffffff;

$success-color: #0d7a0d;
$warn-color: #faa01e;
$error-color: #df2121;
$primary: #1ea0ef;


@import "../../node_modules/bootstrap/scss/bootstrap.scss";
/* GENERAL */

html,
body,
app-root {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: "Open Sans", Roboto, Arial, sans-serif;
  color: $text-color;
  font-weight: 400;
  background: $background-color;
}

ng-component {
  position: relative !important;
}
a {
  // color: inherit;
  text-decoration: none !important;
  &:hover,
  &:visited {
    // color: inherit;
    text-decoration: none;
  }
}

.bold {
  font-weight: bold;
}

.minheight-10rem {
  min-height: 10rem;
}

.mat-icon.vertical-align-middle {
  vertical-align: middle;
}

.orange-theme-element {
  background: mat-color($app-accent, A700) !important;
  color: mat-contrast($app-accent, A700) !important;
  font-weight: 900 !important;
}
.orange-theme-element[disabled="disabled"],
.orange-theme-element:disabled {
  background: lighten(mat-color($app-accent, A700), 15%) !important;
}

.white-theme-element {
  background: #fff !important;
  color: mat-color($app-primary, 500) !important;
  font-weight: 900 !important;
}
.theme-element {
  background: mat-color($app-primary, 500) !important;
  color: mat-contrast($app-primary, 500) !important;
  font-weight: 900 !important;
}
.ui-inputtext {
  border: 2px solid #ddd !important;
  border-radius: 0;
  padding: 5px;
}

.inputmax input,
.ui-calendar {
  width: 100% !important;
}
.inputmax input {
  padding: 8px;
}

.placeholder-custom {
  font-family: "Open Sans", Roboto, Arial, sans-serif !important;
  letter-spacing: 2px !important;
  color: #474747 !important;
  text-transform: uppercase !important;
  font-size: 14px;
  /*transform: scale(.75);
  font-weight: 600 !important;
  margin-bottom: -3px;
  margin-left: -36px;*/
}

.font-m {
  font-family: "Montserrat", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: $text-color;
}
.text-segment {
  font-family: "Open Sans", Roboto, Arial, sans-serif !important;
  font-weight: 600 !important;
  letter-spacing: 2px !important;
  color: #474747;
  text-transform: uppercase !important;
}
.text-entry {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: $text-color;
}
.barAL {
  background: url("/assets/images/bg.png") center right no-repeat $main-color !important;
}
/*button {
  font-family: 'Open Sans', Roboto, Arial, sans-serif;
  font-weight: 800;
  background: mat-color($app-primary, 500) !important;
  color: mat-contrast($app-primary, 500) !important;
}*/

.hide-underline .mat-form-field-underline {
  display: none;
}
.mat-form-field-placeholder {
  @extend .text-segment;
  font-size: 16px;
}
.mat-select-arrow {
  background: url("/assets/images/down_arrow.png") center center;
  width: 17px !important;
  height: 10px !important;
  border-top: none !important;
}
.mat-select-disabled {
  border: 2px solid #ddd;

  .mat-select-placeholder {
    @extend .text-segment;
    color: #ccc;
  }
}
.w-33 {
  width: 33%;
}

.warn-message {
  background: $warn-color !important;
  color: #fefefe;
  button {
    background: none !important;
    color: #fefefe;
  }
}
.error-message {
  background: $error-color !important;
  color: #fefefe;
  button {
    background: none !important;
    color: #fefefe;
  }
}
.success-message {
  background: $success-color !important;
  color: #fefefe;
  button {
    background: none !important;
    color: #fefefe;
  }
}

a.success-message:hover {
  color: #fefefe;
}

.color-warn {
  color: $warn-color !important;
}
.color-error {
  color: $error-color !important;
}
.color-success {
  color: $success-color !important;
}
.color-light {
  color: $text-color-light !important;
}

.btn-more-info {
  @extend .color-light;
}

mat-select {
  border: 2px solid #999;
  padding: 5px;
  width: 200px;
}
.mat-select-underline {
  display: none;
}
mat-form-field {
  @extend .text-entry;
  input {
    padding: 5px !important;
  }
}

th,
td {
  border: none !important;
}
th {
  white-space: nowrap;
}
.active-indicator {
  width: 100px;
  height: 20px;
  background: #dedede;
  color: #fff;
  text-align: center;
  font-size: 10px;
  line-height: 20px;
  font-weight: 700;
  margin-top: 1px;
  text-transform: uppercase;
  &.active {
    color: mat-color($app-accent, A700) !important;
  }
}
.chart-images {
  position: absolute;
  text-align: center;
  display: block;
  width: 250px;
  height: 250px;
  margin-left: auto;
  margin-right: auto;
  > div {
    line-height: 30px;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: rgba(251, 160, 30, 1);
    font-size: 18px;
  }
  > div.blue {
    color: rgba(29, 160, 240, 1);
  }
}
.blue {
  color: rgba(29, 160, 240, 1);
}
.blue-title {
  @extend .blue;
  font-weight: 600 !important;
  font-size: 15px !important;
  padding: 10px !important;
}
.example-text {
  background: url("/assets/images/example.png") 5px center no-repeat !important;
}
.headerImg {
  background: url("/assets/images/header-img.jpg") center 65% repeat-x;
  background-size: cover;
  height: 250px;
}
/* NEW */

.loginComponent {
  > div {
    width: 370px;
  }
  .logo {
    img {
      width: 250px;
      height: 105px;
      margin: 0 auto;
    }
  }
}
.mat-sidenav-container {
  background: #fff;
}
.icon-custom {
  &.car,
  &.export {
    color: rgba(0, 0, 0, 0);
  }
  &.car {
    background: url("/assets/images/fahrzeuge.png") center 6px no-repeat;
  }
  &.car-orange {
    background: url("/assets/images/fahrzeuge_orange_small.png") center 6px no-repeat;
  }
  &.export {
    background: url("/assets/images/export.png") center 3px no-repeat;
  }
  &.export-orange {
    background: url("/assets/images/export_orange_small.png") center 3px no-repeat;
  }
  &.home-orange {
    background: url("/assets/images/home_orange_small.png") center 3px no-repeat;
  }
  &.bus {
    background: url("/assets/images/woche.png") center 1px no-repeat;
    color: rgba(0, 0, 0, 0);
    background-size: contain;
    width: 40px;
    height: 30px;
  }
  &.sun {
    background: url("/assets/images/monat.png") center center no-repeat;
    background-size: contain;
    color: rgba(0, 0, 0, 0);
    width: 40px;
    height: 30px;
  }
  &.fit_width {
    background: url("/assets/images/fit_width.png") center center no-repeat;
    background-size: contain;
    color: rgba(0, 0, 0, 0);
    width: 40px;
    height: 30px;
  }
}

.app-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 0.8rem;
  padding: 0 1rem;
}

.button-row {
  margin: 0.25rem 0;
}
.button-row a {
  margin: 0.25rem;
}
.button-row a:first-child {
  margin-left: 0;
}
.button-row a:last-child {
  margin-right: 0;
}

.vertical-text-spacer::before {
  content: "|";
  padding: 0 0.2rem;
  color: rgba(0, 0, 0, 0.3);
}

.mat-menu-content {
  background: $main-color !important;
}
.smaller td {
  font-size: 12px;
}
.smaller th {
  font-size: 13px;
}
simple-snack-bar::first-letter {
  text-transform: uppercase;
}
/* SIDENAV */

mat-sidenav {
  /*width: 250px;*/
  background: mat-color($app-primary, 500) !important;
  color: mat-contrast($app-primary, 500) !important;
  box-shadow: -3px 0 6px rgba(0, 0, 0, 0.24);
  z-index: 1;
  .mat-nav-list .mat-list-item .mat-list-item-content {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: mat-contrast($app-primary, 500);
    font-size: 16px;
  }
  mat-nav-list.main-menu mat-list-item {
    display: block;
    font-size: 16px;
    width: 100%;
    text-align: left !important;
    border-radius: 0 !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    a {
      color: mat-contrast($app-primary, 500) !important;
      .mat-list-item-content {
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        color: mat-contrast($app-primary, 500);
        font-size: 16px;
      }
    }
  }
  a.active {
    border-left: 7px solid mat-color($app-accent);
    .mat-list-item-content {
      padding: 0 16px !important;
    }
  }
  .profile {
    text-align: center;
    /*width: 230px;*/
  }
  .more {
    float: right;
    width: 24px;
    .mat-list-item-content {
      padding: 0 !important;
      text-align: center;
    }
  }
  .portrait {
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid #ffffff;
    width: 48px;
    height: 48px;
    overflow: hidden;
    background: $background-color;
    margin: 0 auto;
    text-align: center;
    img {
      display: block;
      height: 100%;
      width: 100%;
      min-width: 100%;
      min-height: 100%;
    }
  }
  .mat-badge-medium.mat-badge-after {
    margin-right: 0 !important;
  }
}
mat-sidenav.expanded {
  width: 230px;
  .portrait {
    cursor: pointer;
    border: 3px solid #ffffff;
    width: 150px;
    height: 150px;
  }
  .mat-nav-list .mat-list-item .mat-list-item-content {
    padding: 0 23px;
  }
  mat-nav-list.main-menu mat-list-item {
    padding: 0 23px;
    a {
      .mat-list-item-content {
        padding: 0 23px;
      }
    }
  }
  .mat-badge-medium.mat-badge-after {
    margin-right: 22px !important;
  }
}

/* REGISTER */

.support-text {
  line-height: 50px;
  font-weight: 900;
  color: #2a9ce5;
  font-size: 16px;
  span {
    font-weight: 600;
  }
}
.inner-router {
  width: 100%;
}
app-stepindicator {
  width: 730px;
  height: 70px;
  .number {
    width: 220px;
    height: 70px;
    position: relative;
    float: left;
    background-position: right;
    background-repeat: no-repeat;
  }
  .number.one {
    width: 70px;
    background-image: url("/assets/images/1step_filled.png");
  }
  .number.two {
    background-image: url("/assets/images/2step.png");
  }
  .number.two.filled {
    background-image: url("/assets/images/2step_filled.png");
  }
  .number.three {
    background-image: url("/assets/images/3step.png");
  }
  .number.three.filled {
    background-image: url("/assets/images/3step_filled.png");
  }
  .number.four {
    background-image: url("/assets/images/4step.png");
  }
  .number.four.filled {
    background-image: url("/assets/images/4step_filled.png");
  }

  .number.two::after,
  .number.three::after,
  .number.four::after {
    content: " ";
    width: 140px;
    border-top: 4px solid #ededed;
    height: 1px;
    position: absolute;
    top: 33px;
    left: 5px;
  }
  .number.two.filled::after,
  .number.three.filled::after,
  .number.four.filled::after {
    border-top: 4px solid #2a9ce5;
  }
}
@media (max-width: 750px) {
  app-stepindicator {
    width: 400px;
    .number {
      width: 100px;
    }
    .number.two::after,
    .number.three::after,
    .number.four::after {
      width: 33px;
    }
  }
}
.border-img {
  border: 4px solid #ededed;
}
.map-error {
  position: absolute;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.5) !important;
  top: 0;
  height: 300px;
  text-align: center;
  min-width: 100%;
  left: 0;
  line-height: 300px;
  color: #fff;
  font-weight: 600;
}
.crossed {
  color: mat-color($app-accent, A700) !important;
}

.ui-datepicker-year {
  /* prevent year from being right next to month name */
  padding: 0 5px;
}

.sideBarLabel {
  margin-left: 16px;
}

.overlay-icon {
  display: inline-block;
  margin: 55px 0px 0 0;
  padding: 5px 10px;
  background: #d6d6d6;
  text-align: center;
  z-index: 1;
}

.hide {
  display: none;
}

.mat-button-span {
  margin-left: 5px;
}

@import "retina";

.in-middle {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: justify;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  font-weight: lighter;
}

.in-middle:after {
  content: "";
  display: inline-block;
  width: 100%;
}

.custom-modalbox {
  min-height: 639px;
  min-width: 947px;
  .btn-text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    color: rgba(0, 0, 0, 0.64);
  }

  .btn-desc {
    white-space: nowrap;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 42px;
    margin: 0 !important;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;
  }

  // hide border from dialog
  mat-dialog-container {
    overflow: hidden;
    padding: 0 !important;
    margin: 0 !important;

    //hide if inside dialog
    .hide-dialog {
      display: none !important;
    }

    //only in dialog
    .only-dialog {
      display: block !important;
    }

    .step-one {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 44px;
      /* identical to box height */
      color: #000000;
    }

    .step-two {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 44px;
      /* identical to box height */
      color: #000000;
    }

    .step-three {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 44px;
      /* identical to box height */
      color: #000000;
    }
  }
}

//desktop
@media screen and (min-width: 992px) {
  .custom-modalbox {
    .info-group {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }

    .info-connected {
      width: 66% !important;
      
    }

    .info-box {
      width: 33% !important;
    }
    .card-body {
      height: 250px;
    }
    .dialog-box {
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

      .card {
        width: 32% !important;
        min-width: inherit !important;
      }

      .card-title {
        font-size: 1em;
        white-space: nowrap;
        padding: 0 !important;
        margin: 0 !important;
      }

      .card-body {
        padding: 0 !important;
        padding-top: 10px !important;
        height: auto;
      }

      .btn {
        font-size: 1em;
      }
    }
  }
}

//mobile
@media screen and (max-width: 992px) {
  .custom-modalbox {
    .title {
      text-align: center;
      font-size: 7vw !important;
      white-space: nowrap;
      padding: 0 !important;
      
      padding-top: 10px !important;
    }

    .first-step-btn {
      right: 5% !important;
    }
    max-width: 95vw !important;
    .dialog-box {
      justify-content: center !important;
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

      .card {
        width: 80% !important;
      }

      .card-title {
        font-size: 1em;
        white-space: nowrap;
        padding: 0 !important;
        margin: 0 !important;
      }

      .card-body {
        padding: 0 !important;
        padding-top: 10px !important;
      }

      .btn {
        font-size: 1em;
      }
    }

    .setof {
      max-width: 100% !important;
      padding: 0 !important;
    }
    min-width: inherit !important;
    min-height: inherit !important;
    mat-dialog-container {
      margin: auto !important;

      //reset
      .dialog-window {
        min-width: inherit !important;
        min-height: inherit !important;
        height: 90vh !important;
        width: 98vw !important;
      }
    }
  }
  //hide if on mobile
  .hide-mobile {
    display: none !important;
  }
}

.only-dialog {
  display: none;
}

.dialog-window {
  height: 639px;
  width: 947px;
  min-height: 639px;
  min-width: 947px;
}
