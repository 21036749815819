@import '@angular/material/theming';
@import 'palette';

.mat-checkbox-layout {
  white-space: normal !important;
}

@include mat-core();

$app-primary: mat-palette($md-primary);
$app-accent: mat-palette($md-primary, A700);
$app-warn: mat-palette($mat-red);

$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

@include angular-material-theme($app-theme);
