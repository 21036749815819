@media only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-device-pixel-ratio: 2) {

  .mat-select-arrow {
    background: url("/assets/images/down_arrow@2x.png.png") center center;
  }

  .icon-custom {
    &.car {
      background: url('/assets/images/fahrzeuge@2x.png.png') center 6px no-repeat;
      background-size: 21px 11px;
    }
    &.export {
      background: url('/assets/images/export@2x.png.png') center 3px no-repeat;
      background-size: 12px 15px;
    }
    &.bus {
      background: url('/assets/images/woche@2x.png.png') center 1px no-repeat;
      background-size: contain;
    }
    &.sun {
      background: url('/assets/images/monat@2x.png.png') center center no-repeat;
      background-size: contain;
    }
    &.fit_width {
      background: url("/assets/images/fit_width.png") center center no-repeat;
      background-size: contain;
    }
  }

  app-stepindicator {
    .number.one {
      width: 70px;
      background-image: url('/assets/images/1step_filled@2x.png.png');
      background-size: 70px 70px;
    }
    .number.two {
      background-image: url('/assets/images/2step@2x.png.png');
      background-size: 70px 70px;
    }
    .number.two.filled {
      background-image: url('/assets/images/2step_filled@2x.png.png');
      background-size: 70px 70px;
    }
    .number.three {
      background-image: url('/assets/images/3step@2x.png.png');
      background-size: 70px 70px;
    }
    .number.four.filled {
      background-image: url('/assets/images/4step_filled@2x.png.png');
      background-size: 70px 70px;
    }.number.four {
      background-image: url('/assets/images/4step@2x.png.png');
      background-size: 70px 70px;
    }
    .number.three.filled {
      background-image: url('/assets/images/3step_filled@2x.png.png');
      background-size: 70px 70px;
    }
  }
}


.radio-button {
  margin: 5px;
}

.selected-value {
  margin: 15px 0;
}
