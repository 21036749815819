@import "tailwindcss/base";

@import "tailwindcss/components";

@tailwind base;
@tailwind components;
@tailwind utilities;

.supercard {
    @apply overflow-hidden rounded-lg bg-gray-100 border-gray-300 shadow
}

.overlineLabel {
    @apply uppercase text-xs font-light text-gray-500 tracking-widest
}

@import "tailwindcss/utilities";

@import "theming/theme";
@import "theming/main";
@import "theming/sortable-table";

@import "bootstrap/scss/bootstrap";

.material-icons {
    /* Support for IE11 */
    font-feature-settings: 'liga';
}

.position-absolute {
   position: absolute !important;
}

.full-width {
    width: 100%;
}

.mat-card-header-text {
    margin: 0 0 !important;
}

.color-grey-light {
    color: rgba(0, 0, 0, 0.54);
}

.nopadding-right {
    padding-right: 0 !important;
 }

a.hover-underline:hover {
    text-decoration: underline !important;
}

.clearer {
    clear: both;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.dark-backdrop {
    background: #000;
    opacity: 0.75 !important;
}

.mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow {
    opacity: 0.50 !important;
    transform: translateY(0px) !important;
}

